import React from 'react'
import mainPhoto from '../images/filt2.jpg'

const Hero = () => {
    return (
        <div className='text-white Fixed mt-[-3.2rem]'>
            <div className=''>
                <img src={mainPhoto} alt="/" className='h-90 overflow:hidden' />
            </div>
        </div>
    )
}

export default Hero