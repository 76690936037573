import React from 'react'
import AlbumArt from '../images/ChanceToSteal_LearningToBeSad_coverMedium.jpg'

const Album = () => {
    return (
        <div className='w-full text-[#C3C3C3] py-4 px-4'>
            <div className='max-w-[960px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2'>
                <img src={AlbumArt} alt="/" className="w-[250px] md:w-[350px] lg:w-[500px] mx-auto my-4" />
                <div className='flex flex-col items-center md:items-start justify-center mx-auto'>
                    <p className='uppercase text-[#3F9FB4] font-bold'>New Album Out Now</p>
                    <h1 className='text-3xl md:text-4x1 lg:text-5xl font-bold py-1'>Learning to be Sad</h1>
                    <a href="https://distrokid.com/hyperfollow/chancetosteal/learning-to-be-sad?utm_campaign=website&utm_medium=Email+&utm_source=SendGrid"><button className='bg-[#9c34d6] w-[200px] rounded-md font-medium my-4 mx-auto md:mx-0 py-2 text-[#C3C3C3] hover:scale-110 hover:text-[#9c34d6] hover:bg-[#c3c3c3] hover:border-[#9c34d6] hover:border-[2px] duration-200'>Listen</button></a>
                </div>
            </div>
        </div >

    )
}

export default Album