import React from 'react'
import LiveAction from '../../images/LiveAction.jpg'
import Footer from '../../components/Footer';
import Calendar from '../../components/Calendar';

const Shows = () => {
    return (
        <div className='text-white Fixed mt-[-3.2rem]'>
            <div>
                <img src={LiveAction} alt="/" className='h-90 overflow:hidden' />
            </div>
            <div>
                <Calendar />
                <Footer />
            </div>
        </div>

    )
}

export default Shows
