import React from 'react';
import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
// import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
//import Main from './components/Main';
// import Hero from './components/Hero';
// import Album from './components/Album';
// import Videos from './components/Videos';
// import Calendar from './components/Calendar';
// import Footer from './components/Footer';
import Music from './components/Pages/Music';
import Shows from './components/Pages/Shows';
import Media from './components/Pages/Media';
import Home from './components/Pages/Home';

// import { ParallaxProvider } from 'react-scroll-parallax';

function MyComponent({ children }) {
  const [scrollTop, setScrollTop] = useState(0);
  // let testvar = 'yesyesyes';
  let atTopOfPage = true;

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (scrollTop > 0) {
    atTopOfPage = 0;
    //testvar = "nonono";
  }
  else {
    atTopOfPage = 1;
  }

  const classes = `transition-opacity duration-1000 sticky
  ${atTopOfPage ? "opacity-100" : "opacity-0"
    }`;

  return (
    <div className={classes}>
      {children}
      {/* <p>Current scroll position: {scrollTop}</p>
      <p>The nmessage is: {testvar}</p>
      <p>at top or: {atTopOfPage}</p> */}
    </div>
  );
}

function App() {

  return (
    <div className=''>
      <MyComponent>
        <Navbar />
      </MyComponent>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Music" element={<Music />}></Route>
        <Route path="/Shows" element={<Shows />}></Route>
        <Route path="/Media" element={<Media />}></Route>
      </Routes>

      {/* <Hero />
      <Album />
      <Videos />
      <Calendar />
      <Footer /> */}
    </div>
  );
}

export default App;
