import React from 'react'

const Calendar = () => {
    return (
        <div className='w-full bg-[#060C1D] text-[#c3c3c3] h-200 py-4 px-8 mb-10'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-1'>
                <h1 className='text-3xl md:text-4x1 lg:text-5xl font-extrabold uppercase flex flex-auto justify-center py-4 px-4'>SHOWS</h1>
            </div>

            <div className='mx-auto py-2 px-4'>
                {/* date / event / location */}
                <div className='hidden md:grid grid-cols-3 border-b-[1px] border-b-[#3F9FB4] bg-[#3F9FB4] text-[#060C1D] font-extrabold rounded py-1 px-6'>
                    <div className='px-1'>Date</div>
                    <div className='px-1'>Event</div>
                    <div className='px-1'>Location</div>
                </div>

                {/* Copy this div for new show */}
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 border-b-[2px] border-b-[#3F9FB4] py-2 px-6'>
                    <div id='date' className='text-3xl md:text-xl lg:text-xl'>
                        <p className='px-1'>Friday, Feb 21st</p>
                    </div>
                    <div id='event' className='text-xl md:text-xl lg:text-xl'>
                        <p className='px-1'>Chance to Steal @ Darrell's w/ Me, Grimlock - Dead Streets - Corliss</p>
                    </div>
                    <div id='location' className='text-sm md:text-xl lg:text-xl'>
                        <p className='px-1'>Darrell's Tavern</p>
                        <p className='px-1'>18041 Aurora Ave N, Seattle, WA 98133</p>
                    </div>
                </div>
                {/* End Copy this div for new show */}

                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 border-b-[2px] border-b-[#3F9FB4] my-4 md:my-0 lg:my-0 py-2 px-6'>
                    <div id='date' className='text-3xl md:text-xl lg:text-xl'>
                        <p className='px-1' >Friday, Apr 18th</p>
                    </div>
                    <div id='event' className='text-xl md:text-xl lg:text-xl'>
                        <p className='px-1' >Chance to Steal @ Blue Moon w/ Gniffke</p>
                    </div>
                    <div id='location' className='text-sm md:text-xl lg:text-xl'>
                        <p className='px-1'>Blue Moon Tavern</p>
                        <p className='px-1'>712 Ne 45th St, Seattle, WA 98133</p>
                        <p className='px-1 text-lg text-[#9C34D6] hover:text-[#3F9FB4] duration-500'><a href="https://www.thebluemoonseattle.com/calendar">Get Tickets</a></p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Calendar