import React from 'react'
import Hero from '../../components/Hero';
import Album from '../../components/Album';
import Videos from '../../components/Videos';
import Calendar from '../../components/Calendar';
import Footer from '../../components/Footer';

const Home = () => {
    return (
        <div>
            <Hero />
            <Album />
            <Videos />
            <Calendar />
            <Footer />
        </div>
    )
}

export default Home
