import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import {
  FaYoutube,
  FaFacebookSquare,
  FaInstagramSquare,
  FaSpotify
} from 'react-icons/fa'

const Navbar = () => {

  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
    if (!nav) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }



  return (
    <nav className='bg-[#250b3c]'>
      <div className="'flex justify-between items-center h-14 max-w-[1240px] mx-auto px-4 text-[#c3c3c3]'">
        <div className="flex items-center justify-between h-14">
          <Link to="/"><h1 className='w-full text-3xl font-bold text-[#C3C3C3] m-4 uppercase'>Chance to Steal</h1></Link>
          <div className="flex space-x-4 text-[#C3C3C3]">
            <ul className='hidden md:flex uppercase'>
              {/* <li className='p-4'><Link className='hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500' to="/">Home</Link></li> */}
              <li className='p-4'><Link className='hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500' to="/Music">Music</Link></li>
              <li className='p-4'><Link className='hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500' to="/Shows">Shows</Link></li>
              <li className='p-4'><Link className='hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500' to="/Media">Media</Link></li>
            </ul>
          </div>
          <div onClick={handleNav} className='block md:hidden text-[#c3c3c3]'>
            {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
          </div>
          <div className={nav ? 'fixed left-0 top-0 w-[100%] h-full border-r text-[#c3c3c3] border-r-gray-900 bg-[#060C1D] ease-in-out duration-100' : 'fixed left-[-100%]'}>
            <div className="flex items-center justify-end h-14 px-4 ">

              {/* <h1 className='w-full text-3xl font-bold text-[#060C1D] m-4 uppercase'>Chance to Steal</h1> */}

              <div onClick={handleNav} className='block md:hidden text-[#c3c3c3]'>
                {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
              </div>
            </div>

            <ul className='uppercase p-4 top-[-3rem] text-2xl'>
              <li onClick={handleNav} className='flex justify-center p-4 border-b border-gray-600 hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500'><Link to="/">Home</Link></li>
              <li onClick={handleNav} className='flex justify-center p-4 border-b border-gray-600 hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500'><Link to="/Music">Music</Link></li>
              <li onClick={handleNav} className='flex justify-center p-4 border-b border-gray-600 hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500'><Link to="/Shows">Shows</Link></li>
              <li onClick={handleNav} className='flex justify-center p-4 border-b border-gray-600 hover:text-[#C3C3C3] transition-colors duration-300 transform dark:hover:text-[#3F9FB4] hover:border-blue-500'><Link to="/Media">Media</Link></li>
            </ul>
            <div className='flex justify-center w-full mx-auto text-[#c3c3c3] h-200 py-4 px-4'>
              <div className='grid grid-cols-4 mx-4'>
                <div className='w-[40px] flex justify-center'><a className='hover:bg-[#3F9FB4] duration-300' href="https://www.facebook.com/chancetosteal"><FaFacebookSquare size={30} /></a></div>
                <div className='w-[40px] flex justify-center'><a className='hover:bg-[#3F9FB4] duration-300' href="https://www.instagram.com/chancetosteal"><FaInstagramSquare size={30} /></a></div>
                <div className='w-[40px] flex justify-center'><a className='hover:bg-[#3F9FB4] duration-300' href="https://www.youtube.com/@chancetosteal"><FaYoutube size={30} /></a></div>
                <div className='w-[40px] flex justify-center'><a className='hover:bg-[#3F9FB4] duration-300' href="https://open.spotify.com/artist/3zKxOKByP1BRaJDbxVjhpW?si=SYZm7hR9QvG-0Gijs877NQ"><FaSpotify size={30} /></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
