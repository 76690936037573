import React from 'react'

const Videos = () => {
    return (
        <div className='w-full bg-[#c3c3c3] text-[#060C1D] h-200 py-3 px-4'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-1'>
                <h1 className='text-3xl md:text-4x1 lg:text-5xl font-extrabold uppercase flex flex-auto justify-center py-4 px-4'>VIDEOS</h1>
            </div>

            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 my-[-14px] py-2 mb-6'>
                <div className='flex flex-col justify-center px-4'>
                    <iframe className='w-full aspect-video' src="https://www.youtube.com/embed/pz0ytd3YIGM?si=QRtjIYa64-8h3Lhq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p className='font-bold' >Race to the Bottom - Official Lyric Video</p>
                </div>
                <div className='flex flex-col justify-center px-4 my-6'>
                    <iframe className='w-full aspect-video' src="https://www.youtube.com/embed/YOhOw2O3lMU?si=CnOp_FB0uJdbX7tA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p className='font-bold' >Race to the Bottom - Live Playthrough</p>
                </div>
            </div>
        </div >
    )
}

export default Videos