import React from 'react'
import Footer from '../../components/Footer';
import Videos from '../../components/Videos';
//import Photos from '../Photos';

const Media = () => {
    return (
        <div className=''>
            <div className=''>
                <Videos />
                {/* <Photos /> */}
                <Footer />
            </div>
        </div>
    )
}

export default Media