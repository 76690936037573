import React from 'react'
import Footer from '../../components/Footer';
import Album from '../../components/Album';

const Shows = () => {
    return (
        <div className='text-white Fixed'>

            <div className=''>
                <Album />
                <div className='p-8 bg-[#c3c3c3] text-[#060C1D] border-b-[#060C1D] border-b-[80px]'>
                    <div className='max-w-[960px] mx-auto grid md:grid-cols-1'>
                        <h1 className='text-3xl md:text-4xl lg:text-5xl font-extrabold uppercase flex flex-auto justify-center py-2 px-4'>SINGLES</h1>
                        {/* <h1 className='lg:text-5xl md:text-4x1 sm:text-3xl text-2xl font-extrabold uppercase flex flex-auto justify-center py-2 px-4'>SINGLES</h1> */}
                    </div>
                    <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 my-[-8px] py-4'>
                        <div className='flex flex-col justify-center px-4'>
                            <iframe className='w-full aspect-video' title="Single - Race to the Bottom" src="https://open.spotify.com/embed/album/4Gmcm7iPZLsm8kCyFredRx?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            <p className='font-bold'>Race to the Bottom</p>
                        </div>
                        <div className='my-6 flex flex-col justify-center px-4'>
                            <iframe className='w-full aspect-video' title="Single - Hate Sells" src="https://open.spotify.com/embed/album/4Y3TrFcUqXg2HN3POvvEI0?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            <p className='font-bold'>Hate Sells</p>
                        </div>
                    </div>
                </div>
                <div className='p-8 bg-[#c3c3c3] text-[#060C1D] border-b-[#060C1D] border-b-[80px]'>
                    <div className='max-w-[1240px] mx-auto grid md:grid-cols-1'>
                        <h1 className='text-3xl md:text-4xl lg:text-5xl font-extrabold uppercase flex flex-auto justify-center py-2 px-4'>ALBUMS</h1>
                    </div>
                    <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 my-[-8px] py-4 mb-6'>
                        <div className='flex flex-col justify-center px-4'>
                            <iframe className='w-full aspect-video' title="Album - Do It Again, Do It Better" src="https://open.spotify.com/embed/album/7uFJSIXjIz9tDIwSHAuQbs?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            <p className='font-bold'>Do It Again, Do It Better</p>
                        </div>
                        <div className='my-6 flex flex-col justify-center px-4'>
                            <iframe className='w-full aspect-video' title="Album - Learning to be Sad" src="https://open.spotify.com/embed/album/419dKGdlqMXFX704uvvnqc?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            <p className='font-bold'>Learning to be Sad</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default Shows

